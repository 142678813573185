import money from "../assets/money.png";

const items = [
  {
    title: "Laporan Keuangan",
    subtitle:
      "Laporan keuangan & kas Destinasi Wisata & Budaya Tlatar Kandangan",
    image: money,
    link: "https://docs.google.com/spreadsheets/d/1cxeOOXqWEiE4ZLuIuRZk2Tjozkig8uwDlaPsfnKifHs/edit#gid=0", //Github Profile link
  },
];

export default items;
