import React, { useState } from "react";
import Header from "./components/Header";
import Card from "./components/Card";
import Footer from "./components/Footer";
import items from "./assets/items";

import "./css/skeleton.css";
import "./css/normalize.css";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const simpleLogin = () => {
    console.log(username);
    console.log(password);
    if (
      username === process.env.REACT_APP_USERNAME &&
      password === process.env.REACT_APP_PASSWORD
    ) {
      setAuthenticated(true);
    } else {
      setError("Username atau password salah!");
    }
  };

  if (authenticated)
    return (
      <div className="App">
        <Header></Header>
        <div className="container row">
          {items.map((item, i) => {
            return (
              <Card
                i={i}
                title={item.title}
                subtitle={item.subtitle}
                link={item.link}
                cover={item.image}
              ></Card>
            );
          })}
        </div>
        <br />
        <Footer></Footer>
      </div>
    );
  else {
    return (
      <div
        style={{
          width: "100%",
          paddingTop: "3rem",
        }}
      >
        <label
          style={{
            width: "90vw",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Username
        </label>
        <input
          type="text"
          onChange={(e) => {
            setUsername(e.target.value);
            setError("");
          }}
          style={{
            width: "90vw",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></input>
        <br />
        <label
          style={{
            width: "90vw",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Password (Kata Sandi)
        </label>
        <input
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
            setError("");
          }}
          style={{
            width: "90vw",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></input>
        <br />
        <button
          onClick={simpleLogin}
          style={{
            width: "90vw",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            background: "blue",
            color: "white",
          }}
        >
          Login
        </button>
        <p
          style={{
            textAlign: "center",
            color: "red",
          }}
        >
          {error}
        </p>
      </div>
    );
  }
}

export default App;
