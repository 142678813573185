import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {
    return (
      <div className="Footer container">
        <p>
          Perhatian: website ini dibuat oleh pengelola Destinasi Wisata & Budaya
          Tlatar Kandangan khusus untuk warga Tlatar Kandangan. Jangan berikan
          username dan password (kata sandi) kepada siapapun yang bukan warga
          Tlatar.
        </p>
      </div>
    );
}

export default Footer
